import { environment } from '../environments/environment';
import { UserDto } from './models.dto';

export const USER_ID_KEY = 'sot_user_id';

export class DSAPI {
  rootUri: string;
  baseUri: string;

  constructor() {
    this.rootUri = `${environment.serverUrl}/`;
    this.baseUri = `${this.rootUri}${environment.serverBasePath}`;
  }

  async _get(path: string) {        
    const response = await fetch(this.baseUri + path, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    
    if (response.status === 404) {
      return null;
    }
    const json = await response.json();
    return json;
  }

  async getSoTUserDetails(userId: string) {    
    const json: UserDto = await this._get(`/sot/${userId}`);    
    
    return json;
  }
}
